<script setup>
const root = ref(null)

defineProps({
    data: {
        type: Object,
        default: () => ({}),
    },
})
</script>

<template>
    <div
        ref="root"
        class="my-8 justify-between px-12 lg:flex xl:my-30 xl:px-23"
    >
        <div class="xl:w-7/12">
            <h2
                v-if="data.sectionTitle"
                class="mb-8 text-4xl lg:mb-13 lg:text-6xl"
            >
                <ClientOnly>
                    <SplitText>
                        <div v-html="parseMarkdown(data.sectionTitle)" />
                    </SplitText>
                    <template #fallback>
                        <div v-html="parseMarkdown(data.sectionTitle)" />
                    </template>
                </ClientOnly>
            </h2>
            <Parallax
                :animation="false"
                :leave="false"
            >
                <div
                    v-if="data.subtitle"
                    class="block h-[2px] w-[139px] bg-current"
                />
            </Parallax>
            <h3
                v-if="data.subtitle"
                class="mb-13 pt-6 text-xl uppercase lg:text-sm"
            >
                <Parallax
                    :animation="false"
                    :leave="false"
                >
                    <div v-html="parseMarkdown(data.subtitle)" />
                </Parallax>
            </h3>
            <div class="mb-8 xl:hidden">
                <SvgAnimatedLines
                    v-if="data?.imageRaw?.[0]?.mimeType === 'image/svg+xml'"
                    :svg="data?.imageRaw?.[0]?.svg"
                    class="svgimg svg:h-auto svg:w-full"
                />

                <Parallax
                    v-else
                    :animation="false"
                    :leave="false"
                >
                    <img
                        v-if="data?.image?.[0]?.url"
                        :src="data.image[0].url"
                        :srcset="data.image[0].srcset"
                        :alt="data.image[0].alt"
                    >
                </Parallax>
            </div>
            <ul
                v-if="data?.links"
                class="md:flex md:flex-wrap md:gap-x-4"
            >
                <li
                    v-for="(link, linkIndex) in data.links"
                    :key="'twocolumnslink' + linkIndex"
                    class="mb-8 uppercase md:mb-6 md:w-[48%] 2xl:w-[30%]"
                >
                    <Button
                        :to="link.url"
                        circle="bg-transparent border-2 border-gold !w-8 !h-8"
                        arrow="fill-white"
                        text="text-white"
                    >
                        <span v-html="link.text" />
                    </Button>
                </li>
            </ul>
        </div>
        <div class="hidden xl:block xl:w-2/5">
            <SvgAnimatedLines
                v-if="data?.imageRaw?.[0]?.mimeType === 'image/svg+xml'"
                :svg="data?.imageRaw?.[0]?.svg"
                class="svgimg svg:h-auto svg:w-full"
            />
            <Parallax
                v-else
                :animation="false"
                :leave="false"
            >
                <img
                    v-if="data?.image?.[0]?.url"
                    :src="data.image[0].url"
                    :srcset="data.image[0].srcset"
                    :alt="data.image[0].alt"
                >
            </Parallax>
        </div>
    </div>
</template>

<style scoped lang="postcss">
:deep(strong) {
    @apply text-gold font-semibold;
}

:deep(.svgimg path, .svgimg circle, .svgimg polygon, .svgimg rect, .svgimg ellipse, .svgimg polyline, .svgimg line) {
    visibility: hidden;
    stroke-dasharray: 1;
    stroke-dashoffset: 1;
}
</style>
